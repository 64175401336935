import { Editor } from "@tiptap/react";
import React, { Component } from "react";
import FileUpload from "./FileUpload";
import { StorageApi } from "@mihhdu/api-client";
import {
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaAlignJustify,
  FaItalic,
  FaBold,
  FaStrikethrough,
  FaUnderline,
  FaLink,
  FaLaptopCode,
  FaParagraph,
  FaPalette,
  FaChevronDown,
  FaList,
  FaFileAudio,
  FaPerbyte,
  FaFileImage,
  FaFileVideo,
  FaYoutube,
  FaListOl,
  FaTable,
} from "react-icons/fa6";
import { BsImages } from "react-icons/bs";
import {
  TbArrowBadgeLeft,
  TbArrowBadgeRight,
  TbColumnInsertLeft,
  TbColumnInsertRight,
  TbColumnRemove,
  TbRowInsertBottom,
  TbRowInsertTop,
  TbRowRemove,
  TbTableColumn,
  TbTableDown,
  TbTableFilled,
  TbTableMinus,
  TbTableRow,
  TbTableShortcut,
  TbTable,
} from "react-icons/tb";
import { VscHorizontalRule } from "react-icons/vsc";
import { FcTodoList } from "react-icons/fc";

interface EditorMenuBarProps {
  editor: Editor;
  setLink?: () => void;
  addImage?: () => void;
  addAudio?: () => void;
  addVideo?: () => void;
  addYoutube?: () => void;
  addIFrame?: () => void;
  storage?: StorageApi;
  tenant?: string;
}

export class EditorMenuBar extends Component<
  EditorMenuBarProps,
  { activeDropdown: string | null }
> {
  private menuRef: React.RefObject<HTMLDivElement>;

  // Dropdown type constants
  private static DROPDOWN_TYPES = {
    FORMAT: "format",
    STYLE: "style",
    ALIGN: "align",
    COLOR: "color",
    LISTS: "lists",
    MEDIA: "media",
    TABLE: "table",
    TABLE_OPERATIONS: "table-operations",
  };

  constructor(props: EditorMenuBarProps) {
    super(props);
    this.state = {
      activeDropdown: null,
    };
    this.menuRef = React.createRef();

    // Bind methods
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleFileUploadComplete = this.handleFileUploadComplete.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event: MouseEvent) {
    if (
      this.menuRef.current &&
      !this.menuRef.current.contains(event.target as Node)
    ) {
      this.setState({ activeDropdown: null });
    }
  }

  toggleDropdown(name: string) {
    this.setState((prevState) => ({
      activeDropdown: prevState.activeDropdown === name ? null : name,
    }));
  }

  handleFileUploadComplete(htmlTag: string) {
    const { editor } = this.props;
    editor.chain().focus().insertContent(htmlTag).run();
  }

  getCurrentHeadingText() {
    const { editor } = this.props;
    if (editor.isActive("heading", { level: 1 })) return "H1";
    if (editor.isActive("heading", { level: 2 })) return "H2";
    if (editor.isActive("heading", { level: 3 })) return "H3";
    if (editor.isActive("heading", { level: 4 })) return "H4";
    return "P";
  }

  getCurrentAlignIcon() {
    const { editor } = this.props;
    if (editor.isActive({ textAlign: "center" })) return <FaAlignCenter />;
    if (editor.isActive({ textAlign: "right" })) return <FaAlignRight />;
    if (editor.isActive({ textAlign: "justify" })) return <FaAlignJustify />;
    return <FaAlignLeft />;
  }

  getTextColor() {
    return this.props.editor.getAttributes("textStyle").color || "currentColor";
  }

  getCurrentStyleIcon() {
    const { editor } = this.props;
    if (editor.isActive("bold")) return <FaBold />;
    if (editor.isActive("italic")) return <FaItalic />;
    if (editor.isActive("underline")) return <FaUnderline />;
    if (editor.isActive("strike")) return <FaStrikethrough />;
    if (editor.isActive("code")) return <FaLaptopCode />;
    return <FaBold />; // Default icon
  }

  getCurrentListIcon() {
    const { editor } = this.props;
    if (editor.isActive("bulletList")) return <FaList />;
    if (editor.isActive("orderedList")) return <FaListOl />;
    if (editor.isActive("taskList")) return <FcTodoList />;
    return <FaList />; // Default icon
  }

  render() {
    const {
      editor,
      setLink,
      addImage,
      addAudio,
      addVideo,
      addYoutube,
      addIFrame,
      storage,
      tenant,
    } = this.props;
    const { activeDropdown } = this.state;
    const { FORMAT, STYLE, ALIGN, COLOR, LISTS, MEDIA, TABLE } =
      EditorMenuBar.DROPDOWN_TYPES;

    return (
      <div
        className="tiptap-bubble-menu"
        ref={this.menuRef}
        style={{ position: "sticky", top: 0, zIndex: 100 }}
      >
        {/* BubbleMenuBar content */}
        {/* Paragraph/Heading Dropdown */}
        <div className="tiptap-dropdown">
          <button
            className="tiptap-dropdown-trigger"
            onClick={() => this.toggleDropdown(FORMAT)}
          >
            {this.getCurrentHeadingText()}{" "}
            <FaChevronDown className="tiptap-dropdown-icon" />
          </button>
          <div
            className={`tiptap-dropdown-content ${activeDropdown === FORMAT ? "tiptap-show" : "tiptap-hide"}`}
          >
            <button
              className={`tiptap-dropdown-item ${editor.isActive("paragraph") ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().setParagraph().run();
                this.setState({ activeDropdown: null });
              }}
            >
              <FaParagraph /> <span>Paragraph</span>
            </button>
            <button
              className={`tiptap-dropdown-item ${editor.isActive("heading", { level: 1 }) ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().toggleHeading({ level: 1 }).run();
                this.setState({ activeDropdown: null });
              }}
            >
              <span>Heading 1</span>
            </button>
            <button
              className={`tiptap-dropdown-item ${editor.isActive("heading", { level: 2 }) ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().toggleHeading({ level: 2 }).run();
                this.setState({ activeDropdown: null });
              }}
            >
              <span>Heading 2</span>
            </button>
            <button
              className={`tiptap-dropdown-item ${editor.isActive("heading", { level: 3 }) ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().toggleHeading({ level: 3 }).run();
                this.setState({ activeDropdown: null });
              }}
            >
              <span>Heading 3</span>
            </button>
            <button
              className={`tiptap-dropdown-item ${editor.isActive("heading", { level: 4 }) ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().toggleHeading({ level: 4 }).run();
                this.setState({ activeDropdown: null });
              }}
            >
              <span>Heading 4</span>
            </button>
          </div>
        </div>

        {/* Text Style Dropdown */}
        <div className="tiptap-dropdown">
          <button
            className="tiptap-dropdown-trigger"
            onClick={() => this.toggleDropdown(STYLE)}
          >
            {this.getCurrentStyleIcon()}{" "}
            <FaChevronDown className="tiptap-dropdown-icon" />
          </button>
          <div
            className={`tiptap-dropdown-content ${activeDropdown === STYLE ? "tiptap-show" : "tiptap-hide"}`}
          >
            <button
              className={`tiptap-dropdown-item ${editor.isActive("bold") ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().toggleBold().run();
                this.setState({ activeDropdown: null });
              }}
            >
              <FaBold /> <span>Bold</span>
            </button>
            <button
              className={`tiptap-dropdown-item ${editor.isActive("italic") ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().toggleItalic().run();
                this.setState({ activeDropdown: null });
              }}
            >
              <FaItalic /> <span>Italic</span>
            </button>
            <button
              className={`tiptap-dropdown-item ${editor.isActive("underline") ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().toggleUnderline().run();
                this.setState({ activeDropdown: null });
              }}
            >
              <FaUnderline /> <span>Underline</span>
            </button>
            <button
              className={`tiptap-dropdown-item ${editor.isActive("strike") ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().toggleStrike().run();
                this.setState({ activeDropdown: null });
              }}
            >
              <FaStrikethrough /> <span>Strikethrough</span>
            </button>
            <button
              className={`tiptap-dropdown-item ${editor.isActive("code") ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().toggleCode().run();
                this.setState({ activeDropdown: null });
              }}
            >
              <FaLaptopCode /> <span>Code</span>
            </button>
          </div>
        </div>

        {/* Alignment Dropdown */}
        <div className="tiptap-dropdown">
          <button
            className="tiptap-dropdown-trigger"
            onClick={() => this.toggleDropdown(ALIGN)}
          >
            {this.getCurrentAlignIcon()}{" "}
            <FaChevronDown className="tiptap-dropdown-icon" />
          </button>
          <div
            className={`tiptap-dropdown-content ${activeDropdown === ALIGN ? "tiptap-show" : "tiptap-hide"}`}
          >
            <button
              className={`tiptap-dropdown-item ${editor.isActive({ textAlign: "left" }) ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().setTextAlign("left").run();
                this.setState({ activeDropdown: null });
              }}
            >
              <FaAlignLeft /> <span>Left</span>
            </button>
            <button
              className={`tiptap-dropdown-item ${editor.isActive({ textAlign: "center" }) ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().setTextAlign("center").run();
                this.setState({ activeDropdown: null });
              }}
            >
              <FaAlignCenter /> <span>Center</span>
            </button>
            <button
              className={`tiptap-dropdown-item ${editor.isActive({ textAlign: "right" }) ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().setTextAlign("right").run();
                this.setState({ activeDropdown: null });
              }}
            >
              <FaAlignRight /> <span>Right</span>
            </button>
            <button
              className={`tiptap-dropdown-item ${editor.isActive({ textAlign: "justify" }) ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().setTextAlign("justify").run();
                this.setState({ activeDropdown: null });
              }}
            >
              <FaAlignJustify /> <span>Justify</span>
            </button>
          </div>
        </div>

        {/* Color Picker */}
        <div className="tiptap-dropdown">
          <button
            className="tiptap-dropdown-trigger"
            onClick={() => this.toggleDropdown(COLOR)}
          >
            <FaPalette style={{ color: this.getTextColor() }} />
            <FaChevronDown className="tiptap-dropdown-icon" />
          </button>
          <div
            className={`tiptap-dropdown-content tiptap-color-picker ${activeDropdown === COLOR ? "tiptap-show" : "tiptap-hide"}`}
          >
            <input
              type="color"
              onChange={(event) => {
                editor
                  .chain()
                  .focus()
                  .setColor((event.target as HTMLInputElement).value)
                  .run();
                this.setState({ activeDropdown: null });
              }}
              value={
                this.getTextColor() !== "currentColor"
                  ? this.getTextColor()
                  : "#000000"
              }
            />
          </div>
        </div>

        {/* Link Button */}
        <button
          className={`tiptap-button ${editor.isActive("link") ? "active" : ""}`}
          onClick={setLink}
        >
          <FaLink />
        </button>

        <button
          className="tiptap-button"
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
        >
          <VscHorizontalRule />
        </button>

        {/* Lists Dropdown */}
        <div className="tiptap-dropdown">
          <button
            className="tiptap-dropdown-trigger"
            onClick={() => this.toggleDropdown(LISTS)}
          >
            {this.getCurrentListIcon()}{" "}
            <FaChevronDown className="tiptap-dropdown-icon" />
          </button>
          <div
            className={`tiptap-dropdown-content ${activeDropdown === LISTS ? "tiptap-show" : "tiptap-hide"}`}
          >
            <button
              className={`tiptap-dropdown-item ${editor.isActive("bulletList") ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().toggleBulletList().run();
                this.setState({ activeDropdown: null });
              }}
            >
              <FaList /> <span>Bullet List</span>
            </button>
            <button
              className={`tiptap-dropdown-item ${editor.isActive("orderedList") ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().toggleOrderedList().run();
                this.setState({ activeDropdown: null });
              }}
            >
              <FaListOl /> <span>Numbered List</span>
            </button>
            <button
              className={`tiptap-dropdown-item ${editor.isActive("taskList") ? "active" : ""}`}
              onClick={() => {
                editor.chain().focus().toggleTaskList().run();
                this.setState({ activeDropdown: null });
              }}
            >
              <FcTodoList /> <span>Task List</span>
            </button>
          </div>
        </div>

        {/* Media Dropdown */}
        {addImage &&
          addAudio &&
          addVideo &&
          addYoutube &&
          addIFrame &&
          storage &&
          tenant && (
            <div className="tiptap-dropdown">
              <button
                className="tiptap-dropdown-trigger"
                onClick={() => this.toggleDropdown(MEDIA)}
              >
                <BsImages /> <FaChevronDown className="tiptap-dropdown-icon" />
              </button>
              <div
                className={`tiptap-dropdown-content ${activeDropdown === MEDIA ? "tiptap-show" : "tiptap-hide"}`}
              >
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    addImage();
                    this.setState({ activeDropdown: null });
                  }}
                >
                  <FaFileImage /> <span>Image</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    addAudio();
                    this.setState({ activeDropdown: null });
                  }}
                >
                  <FaFileAudio /> <span>Audio</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    addVideo();
                    this.setState({ activeDropdown: null });
                  }}
                >
                  <FaFileVideo /> <span>Video</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    addYoutube();
                    this.setState({ activeDropdown: null });
                  }}
                >
                  <FaYoutube /> <span>YouTube</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    addIFrame();
                    this.setState({ activeDropdown: null });
                  }}
                >
                  <FaPerbyte /> <span>iFrame</span>
                </button>
                <div className="tiptap-dropdown-upload">
                  <FileUpload
                    tenant={tenant}
                    onUploadComplete={(htmlTag) => {
                      this.handleFileUploadComplete(htmlTag);
                      this.setState({ activeDropdown: null });
                    }}
                    storage={storage}
                    customClass="in-dropdown"
                  />
                </div>
              </div>
            </div>
          )}

        {/* Table Menu */}
        <div className="tiptap-dropdown">
          <button
            className="tiptap-dropdown-trigger"
            onClick={() => this.toggleDropdown(TABLE)}
          >
            <FaTable /> <FaChevronDown className="tiptap-dropdown-icon" />
          </button>
          <div
            className={`tiptap-dropdown-content ${activeDropdown === TABLE ? "tiptap-show" : "tiptap-hide"}`}
          >
            <button
              className="tiptap-dropdown-item"
              onClick={() => {
                editor
                  .chain()
                  .focus()
                  .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                  .run();
                this.setState({ activeDropdown: null });
              }}
            >
              <TbTable /> <span>Insert 3×3 Table</span>
            </button>

            {editor.isActive("table") && (
              <>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().addColumnBefore().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().addColumnBefore()}
                >
                  <TbColumnInsertLeft /> <span>Add Column Before</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().addColumnAfter().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().addColumnAfter()}
                >
                  <TbColumnInsertRight /> <span>Add Column After</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().addRowBefore().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().addRowBefore()}
                >
                  <TbRowInsertTop /> <span>Add Row Before</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().addRowAfter().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().addRowAfter()}
                >
                  <TbRowInsertBottom /> <span>Add Row After</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().deleteColumn().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().deleteColumn()}
                >
                  <TbColumnRemove /> <span>Delete Column</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().deleteRow().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().deleteRow()}
                >
                  <TbRowRemove /> <span>Delete Row</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().deleteTable().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().deleteTable()}
                >
                  <TbTableMinus /> <span>Delete Table</span>
                </button>

                {/* Additional table operations */}
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().mergeCells().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().mergeCells()}
                >
                  <TbTableShortcut /> <span>Merge Cells</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().splitCell().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().splitCell()}
                >
                  <TbTableDown /> <span>Split Cell</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().toggleHeaderColumn().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().toggleHeaderColumn()}
                >
                  <TbTableColumn /> <span>Toggle Header Column</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().toggleHeaderRow().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().toggleHeaderRow()}
                >
                  <TbTableRow /> <span>Toggle Header Row</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().toggleHeaderCell().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().toggleHeaderCell()}
                >
                  <TbTableRow /> <span>Toggle Header Cell</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().mergeOrSplit().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().mergeOrSplit()}
                >
                  <TbTableShortcut /> <span>Merge Or Split</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().fixTables().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().fixTables()}
                >
                  <TbTableFilled /> <span>Fix Table</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().goToNextCell().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().goToNextCell()}
                >
                  <TbArrowBadgeRight /> <span>Go To Next Cell</span>
                </button>
                <button
                  className="tiptap-dropdown-item"
                  onClick={() => {
                    editor.chain().focus().goToPreviousCell().run();
                    this.setState({ activeDropdown: null });
                  }}
                  disabled={!editor.can().goToPreviousCell()}
                >
                  <TbArrowBadgeLeft /> <span>Go To Previous Cell</span>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default EditorMenuBar;
