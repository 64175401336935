import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./style/App.css";
import "./style/pico.custom.scss";
import projectData from "./project.json";
import {
  NodesApi,
  LinksApi,
  Node as GraphNode,
  Configuration,
  UserProfile,
  StorageApi,
} from "@mihhdu/api-client";
import {
  Auth,
  Register,
  LoginForm,
  RegisterForm,
  PasswordChangeForm,
  ProfileForm,
  Tenants,
  TenantList,
} from "@mihhdu/shared";
import { EditorContent } from "@tiptap/react";
import Operation from "./components/Operation";
import { CustomEditor, EditorMenuBar } from "./components/editor";
import ArticleFooterForm from "./components/ArticleFooter";
import SiteFooter from "./components/SiteFooter";
import { IsRootNode, RootNodeId } from "./utils/Utils";
import UserMenuForm from "./components/navbar/UserMenu";
import BreadCrumbNav from "./components/navbar/BreadCrumb";
import { MovePageTree, PageTree } from "./components/navbar/PageTree";
import ListItemWithIcon from "./components/navbar/ListItemWithIcon";
import { IoCreateOutline } from "react-icons/io5";
import {
  MdDeleteForever,
  MdDriveFileMoveOutline,
  MdEdit,
} from "react-icons/md";
import { GiCancel, GiConfirmed } from "react-icons/gi";
import { GrUpdate } from "react-icons/gr";

function App() {
  const indexPage = projectData.deployment.index;
  const nodeIdParamName = "nodeId";
  const tenantParamName = "tenant";

  const navigate = useNavigate();
  const redirect = useCallback(
    (nodeId: string, tenant: string) => {
      navigate({
        pathname: indexPage,
        search: `?${nodeIdParamName}=${nodeId}&${tenantParamName}=${tenant}`,
      });
    },
    [navigate, indexPage],
  );

  const redirectHome = (tenant: string) => {
    navigate({
      pathname: indexPage,
      search: `?${nodeIdParamName}=root&${tenantParamName}=${tenant}`,
    });
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nodeId = searchParams.get(nodeIdParamName);
  const tenant =
    searchParams.get(tenantParamName) ??
    localStorage.getItem("tenant") ??
    "default";

  const [nodes, setNodes] = useState<GraphNode[]>([]);
  const [selectedNode, setSelectedNode] = useState<GraphNode | null>(null);
  const [htmlPageTitle, setHtmlPageTitle] = useState<string>("");
  const [htmlPageBody, setHtmlPageBody] = useState<string>("");
  const [pageTags, setPageTags] = useState<string[]>([]);
  const [editingMode, setEditingMode] = useState<Operation>(Operation.View);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState<boolean>(false);
  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);
  const [profileModalOpen, setProfileModalOpen] = useState<boolean>(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] =
    useState<boolean>(false);
  const [tenantModalOpen, setTenantModalOpen] = useState<boolean>(false);

  const articleRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);
  const editorMenuRef = useRef<HTMLElement>(null);

  const {
    editor,
    editorBody,
    addImage,
    setLink,
    addAudio,
    addVideo,
    addYoutube,
    addIFrame,
  } = CustomEditor();

  const config = useMemo(
    () =>
      new Configuration({
        basePath: process.env.REACT_APP_SERVER_URL,
      }),
    [],
  );
  const axiosInstance = useMemo(() => axios.create(), []);
  const {
    login,
    logout,
    getProfile,
    changeProfile,
    isLoggedIn: getIsLoggedIn,
  } = Auth({
    authServerBaseUrl: process.env.REACT_APP_SERVER_URL!,
    axiosInstance,
    setIsLoggedIn,
    tenant,
  });

  const { register } = Register({
    authServerBaseUrl: process.env.REACT_APP_SERVER_URL!,
    axiosInstance,
  });

  const { getTenants } = Tenants({
    authServerBaseUrl: process.env.REACT_APP_SERVER_URL!,
    axiosInstance,
  });

  const { tenantListForm } = TenantList({
    baseUrl: process.env.REACT_APP_SERVER_URL!,
    getTenants,
    onClose: () => {
      setTenantModalOpen(false);
    },
  });

  const { profileForm } = ProfileForm({
    callback: () => {
      setProfileModalOpen(false);
    },
    success: async () => {
      const profile = await getProfile();
      setUserProfile(profile);
    },
    profile: userProfile,
    changeProfile: async (
      firstName: string,
      lastName: string,
      email: string,
    ) => {
      return await changeProfile({
        first_name: firstName,
        last_name: lastName,
        email: email,
      });
    },
  });

  const { passwordChangeForm } = PasswordChangeForm({
    callback: () => {
      setChangePasswordModalOpen(false);
    },
    success: () => {
      logout();
    },
    changePassword: async (oldPassword: string, password: string) => {
      return await changeProfile({
        old_password: oldPassword,
        password: password,
      });
    },
  });
  const { loginForm } = LoginForm({
    callback: () => {
      setLoginModalOpen(false);
    },
    login,
  });

  const { registerForm, newTenant } = RegisterForm({
    baseUrl: process.env.REACT_APP_SERVER_URL!,
    callback: () => {
      setRegisterModalOpen(false);
    },
    success: () => {
      if (newTenant !== null && newTenant !== undefined)
        redirectHome(newTenant);
    },
    register,
  });

  const nodeApi = useMemo(
    () => new NodesApi(config, undefined, axiosInstance),
    [config, axiosInstance],
  );
  const linkApi = useMemo(
    () => new LinksApi(config, undefined, axiosInstance),
    [config, axiosInstance],
  );
  const storageApi = useMemo(
    () => new StorageApi(config, undefined, axiosInstance),
    [config, axiosInstance],
  );

  const setNodeState = (node: GraphNode) => {
    setSelectedNode(node);
    setHtmlPageTitle(node?.data?.title || "");
    setHtmlPageBody(node?.data?.body || "");
    setPageTags(node?.data?.tags || []);
    setEditingMode(Operation.View);
  };

  const isEditingContent =
    editingMode === Operation.Update || editingMode === Operation.CreateNode;

  const createNewNode = useCallback(async () => {
    const createNode = async () => {
      try {
        const response = await nodeApi.addNode(tenant, {
          data: {
            body: editorBody,
            title: htmlPageTitle,
            tags: pageTags,
          },
          incoming_links: [
            {
              from: selectedNode?.id,
              to: "", // self
              weight: getRandomInteger(1, 100000), // TODO
            },
          ],
        });
        return response.data;
      } catch (error) {
        console.error("Error creating node: ", error);
      }
    };

    const node = await createNode();
    if (node) {
      setNodes((prevNodes) => {
        const updatedNodes = prevNodes.map((n) => {
          if (n.id === selectedNode?.id) {
            return {
              ...n,
              outgoing_links: [
                ...(n.outgoing_links || []),
                {
                  from: selectedNode?.id,
                  to: node.id,
                  weight: node.incoming_links[0].weight,
                },
              ],
            };
          }
          return n;
        });

        // Add the new node
        return [...updatedNodes, node];
      });
      redirect(node.id!, tenant);
    }
  }, [
    nodeApi,
    selectedNode,
    tenant,
    pageTags,
    redirect,
    editorBody,
    htmlPageTitle,
  ]);

  const saveChanges = useCallback(async () => {
    if (selectedNode) {
      try {
        const updatedNode = await nodeApi.updateNodeById(
          tenant,
          selectedNode?.id!,
          {
            data: {
              body: editorBody,
              title: htmlPageTitle,
              tags: pageTags,
            },
          },
        );
        setNodeState(updatedNode.data);
        setNodes((prevNodes) =>
          prevNodes.map((n) =>
            n.id === selectedNode?.id ? updatedNode.data : n,
          ),
        );
      } catch (error) {
        console.error("Error updating node data:", error);
      }
    }
  }, [editorBody, htmlPageTitle, nodeApi, pageTags, selectedNode, tenant]);

  const startEdit = () => {
    if (isLoggedIn && editingMode === Operation.View) {
      setHtmlPageTitle(selectedNode.data?.title);
      editor?.commands.setContent(htmlPageBody, true);
      setEditingMode(Operation.Update);
    }
  };

  const cancel = useCallback(() => {
    setHtmlPageBody(selectedNode?.data?.body!);
    setHtmlPageTitle(selectedNode?.data?.title!);
    setPageTags(selectedNode?.data?.tags || []);
    setEditingMode(Operation.View);
  }, [selectedNode]);

  useEffect(() => {
    if (!isEditingContent) return;

    const handleClickOutside = async (event: MouseEvent) => {
      const target = event.target as Node;

      // Check if click is inside article, title or editor menu
      const isInsideArticle =
        articleRef.current && articleRef.current.contains(target);
      const isInsideEditorMenu =
        editorMenuRef.current && editorMenuRef.current.contains(target);
      const isInsideTitle =
        titleRef.current && titleRef.current.contains(target);

      // Only save and exit if click is outside both article AND editor menu
      if (!isInsideArticle && !isInsideEditorMenu && !isInsideTitle) {
        if (editingMode === Operation.Update) {
          await saveChanges();
        } else {
          await createNewNode();
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editingMode, saveChanges, createNewNode, isEditingContent]);

  useEffect(() => {
    if (!isEditingContent) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        cancel();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [editingMode, selectedNode, cancel, isEditingContent]);

  useEffect(() => {
    localStorage.setItem("tenant", tenant);
  }, [tenant]);

  useEffect(() => {
    const trySetProfile = async () => {
      if (isLoggedIn && !userProfile) {
        try {
          const profile = await getProfile();
          setUserProfile(profile);
        } catch (error) {
          console.log("failed to fetch profile" + error);
        }
      } else if (!isLoggedIn && userProfile) {
        setUserProfile(null);
      }
    };

    trySetProfile();
  }, [isLoggedIn, getProfile, userProfile]);

  useEffect(() => {
    const fetchNode = async (id: string) => {
      if (id) {
        try {
          const response = await nodeApi.getNodeById(tenant, id);
          return response.data;
        } catch (error) {
          console.log("Error fetch node:", error);
        }
      }
      return null;
    };

    const fetchNodeUpdateState = async () => {
      const n = await fetchNode(nodeId ?? RootNodeId);
      // Use a default object if fetchNode returns null
      const defaultNodeObject = {
        id: nodeId,
        data: {
          title: projectData.errors.not_found_title,
          body: `<h4>${projectData.errors.not_found_body}</h4>`,
        },
      } as GraphNode;

      // Use the fetchedNode if it exists, otherwise use the defaultNodeObject
      const nodeToUpdateState: GraphNode = n ?? defaultNodeObject;

      setNodeState(nodeToUpdateState);
    };

    fetchNodeUpdateState();
  }, [nodeId, tenant, nodeApi]);

  useEffect(() => {
    setIsLoggedIn(getIsLoggedIn());
  }, [getIsLoggedIn]);

  useEffect(() => {
    const fetchNodes = async () => {
      try {
        const nodesResponse = await nodeApi.getNodes(tenant);
        setNodes(nodesResponse.data);
      } catch (error) {
        console.error("Error fetching nodes:", error);
      }
    };

    fetchNodes();
  }, [tenant, nodeApi]);

  function getRandomInteger(min: number, max: number): number {
    const range = max - min + 1;
    const random = Math.random();
    const randomNumber = Math.floor(random * range) + min;
    return randomNumber;
  }

  const renderTitleSection = () => {
    if (selectedNode == null) return null;

    const renderTitle = () => {
      switch (editingMode) {
        case Operation.Delete:
        case Operation.View:
        case Operation.Move:
          return (
            <div
              onDoubleClick={startEdit}
              style={isLoggedIn ? { cursor: "pointer" } : {}}
            >
              {selectedNode.data?.title}
            </div>
          );
        case Operation.Update:
        case Operation.CreateNode:
          return (
            <input
              ref={titleRef}
              value={htmlPageTitle}
              onChange={(e) => setHtmlPageTitle(e.target.value)}
            />
          );
        default:
          break;
      }
    };

    return (
      <hgroup>
        <h2>{renderTitle()}</h2>
      </hgroup>
    );
  };

  const renderArticleContent = () => {
    const renderArticleHeader = () => {
      if (
        selectedNode.data?.author == null ||
        selectedNode.data?.author.trim() === ""
      )
        return null;

      return (
        <header>
          <h6>
            Posted by <b>{selectedNode.data?.author}</b> on{" "}
            {selectedNode.data?.created}
          </h6>
        </header>
      );
    };

    const renderCreateEdit = () => {
      if (editor == null) return <></>;
      return (
        <div ref={articleRef}>
          <EditorContent editor={editor} />
        </div>
      );
    };

    switch (editingMode) {
      case Operation.Delete:
      case Operation.Move:
        return (
          <>
            {renderArticleHeader()}
            <div dangerouslySetInnerHTML={{ __html: htmlPageBody }} />
          </>
        );
      case Operation.View:
        return (
          <>
            {renderArticleHeader()}
            <div
              onDoubleClick={startEdit}
              dangerouslySetInnerHTML={{ __html: htmlPageBody }}
              style={isLoggedIn ? { cursor: "pointer" } : {}}
            />
          </>
        );
      case Operation.CreateNode:
      case Operation.Update:
        return renderCreateEdit();
      default:
        break;
    }
  };
  const renderControlsMenu = () => {
    if (!isLoggedIn) return <></>;

    return (
      <nav
        ref={editorMenuRef}
        style={{ position: "sticky", top: 0, zIndex: 1000 }}
      >
        <ul style={{ marginLeft: 0 }}>
          {isEditingContent && editor && (
            <EditorMenuBar
              setLink={setLink}
              addImage={addImage}
              addAudio={addAudio}
              addVideo={addVideo}
              addYoutube={addYoutube}
              addIFrame={addIFrame}
              editor={editor}
              storage={storageApi}
              tenant={tenant}
            />
          )}
        </ul>

        <ul>
          {editingMode === Operation.View && (
            <>
              <ListItemWithIcon
                tooltip="Edit"
                icon={MdEdit}
                onClick={startEdit}
              />
              {!IsRootNode(selectedNode) && (
                <>
                  <ListItemWithIcon
                    tooltip="Move"
                    icon={MdDriveFileMoveOutline}
                    onClick={() => {
                      setEditingMode(Operation.Move);
                    }}
                  />
                  <ListItemWithIcon
                    tooltip="Delete"
                    icon={MdDeleteForever}
                    onClick={() => {
                      setEditingMode(Operation.Delete);
                    }}
                  />
                </>
              )}
            </>
          )}

          {editingMode === Operation.Delete && (
            <>
              <ListItemWithIcon
                tooltip={projectData.labels.menu.delete_page}
                icon={MdDeleteForever}
                onClick={async () => {
                  if (selectedNode) {
                    try {
                      const parentNodeId = selectedNode.incoming_links![0].from;
                      const links =
                        selectedNode.outgoing_links?.map((element) => {
                          return {
                            from: parentNodeId,
                            to: element.to,
                            weight: element.weight,
                          };
                        }) ?? [];
                      links.forEach(async (element) => {
                        await linkApi.addLink(tenant, element);
                      });
                      await nodeApi.deleteNode(tenant, selectedNode.id!);
                      const nodes = await nodeApi.getNodes(tenant);
                      setNodes(nodes.data);
                      redirectHome(tenant);
                    } catch (error) {
                      console.error("Error deleting node: ", error);
                    }
                  }
                }}
              />
              <ListItemWithIcon
                tooltip={projectData.labels.menu.cancel}
                icon={GiCancel}
                onClick={cancel}
              />
            </>
          )}

          {editingMode === Operation.Update && (
            <>
              <ListItemWithIcon
                tooltip={projectData.labels.menu.update_page}
                icon={GrUpdate}
                onClick={async () => {
                  await saveChanges();
                }}
              />
              <ListItemWithIcon
                tooltip={projectData.labels.menu.cancel}
                icon={GiCancel}
                onClick={cancel}
              />
            </>
          )}

          {editingMode === Operation.CreateNode && (
            <>
              <ListItemWithIcon
                tooltip={projectData.labels.menu.confirm_page}
                icon={GiConfirmed}
                onClick={async () => {
                  await createNewNode();
                }}
              />
              <ListItemWithIcon
                tooltip={projectData.labels.menu.cancel}
                icon={GiCancel}
                onClick={cancel}
              />
            </>
          )}

          {editingMode === Operation.Move && (
            <ListItemWithIcon
              tooltip={projectData.labels.menu.cancel}
              icon={GiCancel}
              onClick={cancel}
            />
          )}
        </ul>
      </nav>
    );
  };

  const renderMenu = () => {
    switch (editingMode) {
      case Operation.Move:
        return (
          <nav>
            <ul>
              {MovePageTree({
                nodes,
                selectedNode,
                tenant,
                handleNodeMove: async (newNodeId: string) => {
                  if (selectedNode && editingMode === Operation.Move) {
                    try {
                      selectedNode?.incoming_links?.forEach(async (l) => {
                        await linkApi.deleteLink(
                          tenant,
                          l.weight!,
                          l.from!,
                          l.to!,
                        );
                      });
                      await linkApi.addLink(tenant, {
                        weight: getRandomInteger(1, 100000),
                        from: newNodeId,
                        to: selectedNode.id,
                      });
                      const nodes = await nodeApi.getNodes(tenant);
                      setNodes(nodes.data);
                      setSelectedNode(
                        nodes.data.find((x) => x.id === selectedNode.id),
                      );
                      setEditingMode(Operation.View);
                    } catch (error) {
                      console.error("Error moving node:", error);
                    }
                  }
                },
              })}
            </ul>
          </nav>
        );
      default:
        return (
          <nav>
            <ul>
              <li>{PageTree({ nodes, tenant })}</li>
            </ul>
          </nav>
        );
    }
  };

  return (
    <>
      <header>
        {renderTitleSection()}
        <nav>
          <ul>
            <li>{renderMenu()}</li>
          </ul>
          <ul>
            {isLoggedIn && editingMode === Operation.View && (
              <ListItemWithIcon
                tooltip={projectData.labels.menu.create_page}
                icon={IoCreateOutline}
                onClick={() => {
                  setHtmlPageTitle(projectData.labels.new_page_title_template);
                  setPageTags([]);
                  editor?.commands.setContent(
                    projectData.labels.new_page_body_template,
                    true,
                  );
                  setEditingMode(Operation.CreateNode);
                }}
              />
            )}
            {UserMenuForm({
              isLoggedIn,
              getProfile: () => userProfile,
              openLoginForm: () => {
                setLoginModalOpen(true);
              },
              openRegisterForm: () => {
                setRegisterModalOpen(true);
              },
              openProfileForm: () => {
                setProfileModalOpen(true);
              },
              openPasswordChangeForm: () => {
                setChangePasswordModalOpen(true);
              },
              openTenantsForm: () => {
                setTenantModalOpen(true);
              },
              logout: async () => logout(),
            })}
          </ul>
        </nav>
        <BreadCrumbNav
          nodes={nodes}
          selectedNode={selectedNode}
          tenant={tenant}
        />
      </header>
      <main className="container">
        {loginModalOpen ? loginForm() : null}
        {registerModalOpen ? registerForm() : null}
        {profileModalOpen ? profileForm() : null}
        {changePasswordModalOpen ? passwordChangeForm() : null}
        {tenantModalOpen ? tenantListForm() : null}
        {selectedNode && (
          <>
            {renderControlsMenu()}
            <article className="view-mode">
              {renderArticleContent()}
              <ArticleFooterForm
                viewMode={
                  editingMode === Operation.View ||
                  editingMode === Operation.Move ||
                  editingMode === Operation.Delete
                }
                tags={pageTags}
                setTags={setPageTags}
              />
            </article>
          </>
        )}
      </main>
      {SiteFooter()}
    </>
  );
}

export default App;
